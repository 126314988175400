import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Hocal/Components/Navbar';
import ContactUs from './Components/ContactUs'; // Corrected import path
import Footer from './Components/Hocal/Components/Footer';
import PrivacyPolicy from './Components/Hocal/Components/PrivacyPolicy';
import TermsConditions from './Components/Hocal/Components/TermsConditions';
import AboutUs from './Components/Hocal/Components/AboutUs';
import HocalChief from './Components/HocalFeatures/HocalChief';
import HocalGuest from './Components/HocalFeatures/HocalGuest';
import ScrollToTop from './Components/ScrollToTop';
import FAQ from './Components/HocalExtra/FaqPage';
import HocalConnect from './Components/HocalFeatures/HocalConnect';
import HocalCreateServiceRequest from './Components/HocalFeatures/HocalCreateService';
import HomePage from './Components/Hocal/HomePage';
import CarMechanic from './Components/Hocal/Components/ServicesOnHocal/CarMechanic';
import TripPlanningWithHocal from './Components/Hocal/Components/ServicesOnHocal/TripPlanningWithHocal';

const App = () => {
  return (
    <Router>
        <ScrollToTop/>
    

      <div style={{ }}>
        <Navbar />
        <Routes>
          
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/hocal-chief" element={<HocalChief/>} />
          <Route path="/hocal-guest" element={<HocalGuest/>} />
          <Route path="/hocal-create-service-request" element={<HocalCreateServiceRequest/>} />
          <Route path="/hocal-connect" element={<HocalConnect/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/about-us" element={<AboutUs/>}/>
          <Route path="/faq" element={<FAQ/>}/>
          <Route path="/car-service" element={<CarMechanic/>}/>
          <Route path="/trip-planning" element={<TripPlanningWithHocal/>}/>




        </Routes>
        
        <Footer/>
      </div>
    </Router>
  );
};

export default App;
