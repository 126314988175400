import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className=" bg-yellow-50 text-black md:mx-[100px] p-5 lg:p-10">
      
      
          <div className="md:col-span-4">
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 py-10'>
            <div>
                <h2 className='text-2xl mb-2 font-bold'>Hocal Services</h2>
                <Link to="/car-service" className="block lg:mt-5 mt-2">Car mechanic</Link>
                <Link to="/trip-planning" className="block lg:mt-5 mt-2">Trip planner</Link>
                <Link to="/hocal-guest" className="block lg:mt-5 mt-2">Home delivery</Link>
                <Link to="/hocal-connect" className="block lg:mt-5 mt-2 mb-5">Emergency service</Link>
              </div>
              <div>
                <h2 className='text-xl mb-2 font-bold'>Hocal Features</h2>
                <Link to="/hocal-chief" className="block lg:mt-5 mt-2">Hocal Chief</Link>
                <Link to="/hocal-create-service-request" className="block lg:mt-5 mt-2">Create Service Request</Link>
                <Link to="/hocal-guest" className="block lg:mt-5 mt-2">Hocal Guest</Link>
                <Link to="/hocal-connect" className="block lg:mt-5 mt-2 mb-5">Hocal Connect</Link>
              </div>
              <div>
                <h2 className='text-xl mb-2 font-bold'>Hocal</h2>
                <Link to="/about-us" className="block lg:mt-5 mt-2">About</Link>
                <Link to="/faq" className="block lg:mt-5 mt-2">FAQ's</Link>
                <Link to="/contact" className="block lg:mt-5 mt-2">Contact</Link>
                <Link to="/terms-conditions" className="block lg:mt-5 mt-2">Terms & Condition</Link>
                <Link to="/privacy-policy" className="block lg:mt-5 mt-2 mb-5">Privacy Policy</Link>
              </div>
              <div>
                <h2 className='text-xl mb-2 font-bold'>Contact Hocal</h2>
                <h3 className="block lg:mt-5 mt-2">Email Address: support@hocal.in</h3>
                <h3 className="block lg:mt-5 mt-2 mb-10">Address: Kanpur Dehat</h3>
                <div className="flex space-x-4">
              <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" aria-label="Hocal Facebook" title="Hocal Facebook">
                <FontAwesomeIcon icon={faFacebook} className="text-black text-xl hover:text-black" />
              </a>
              <a href="https://x.com/hocalServices/" target="_blank" rel="noopener noreferrer" aria-label="Hocal Twitter" title="Hocal Twitter">
                <FontAwesomeIcon icon={faTwitter} className="text-black text-xl hover:text-black" />
              </a>
              <a href="https://www.instagram.com/hocal_services/" target="_blank" rel="noopener noreferrer" aria-label="Hocal Instagram" title="Hocal Instagram">
                <FontAwesomeIcon icon={faInstagram} className="text-black text-xl hover:text-black" />
              </a>
              <a href="https://www.linkedin.com/company/hocalservices/" target="_blank" rel="noopener noreferrer" aria-label="Hocal LinkedIn" title="Hocal LinkedIn">
                <FontAwesomeIcon icon={faLinkedin} className="text-black text-xl hover:text-black mb-5" />
              </a>
            </div>
              </div>
            </div>
          </div>
  
        
      
    </footer>
  );
};

export default Footer;
