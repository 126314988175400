import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <div>

      <Helmet>
        <title>Hocal Privacy Policy | Your Data Protection and Privacy Rights</title>
        <meta name="description" content="Read Hocal Services Private Limited's Privacy Policy to understand how we protect your data and privacy rights." />
        <meta name="keywords" content="Hocal privacy, Hocal Services privacy policy, Hocal privacy policy, Hocal data protection, privacy rights, Hocal privacy policy page, data privacy Hocal, Hocal user privacy" />
      </Helmet>

      <div className='p-5 md:pt-10 my-5 text-[18px] md:px-[100px]  text-black'>
        <h1 className='text-3xl py-4 font-bold'>Privacy Policy</h1>
        <p className='mb-6'>
          We, "Hocal Services Private Limited" ("Company"), are committed to protecting the privacy and security of your personal information. Your privacy is important to us, and maintaining your trust is paramount.
        </p>
        <p className='mb-6'>
          This privacy policy explains how we collect, use, process, and disclose information about you. By using our website/app/platform and services, you consent to the terms of our privacy policy ("Privacy Policy") in addition to our 'Terms of Use', and disclosure of your information from time to time, to keep yourself updated with the changes and updates that we make to this policy.
        </p>

        <h2 className='text-2xl font-semibold mb-3'>Information We Collect</h2>
        <h3 className='text-xl font-semibold mb-2'>Personal Information</h3>
        <ul className='list-disc list-inside mb-4'>
          <li className='mb-2'>
            We may automatically store certain information including but not limited to information about your web browser, IP address, cookies stored on your device, and other information about you based upon your behavior on the website. We use this information to do internal research on our users' demographics, interests, and behavior to better understand, protect, and serve our users.
          </li>
          <li className='mb-2'>
            <strong>Profile Information</strong>: Details such as your business name, services offered, profile picture, name, phone number, date of birth, and gender.
          </li>
          <li className='mb-2'>
            <strong>Location Information</strong>: Data about your physical location, which can be used to match you with nearby users and service requests.
          </li>
        </ul>
        <h3 className='text-xl font-semibold mb-2'>Usage Data</h3>
        <ul className='list-disc list-inside mb-4'>
          <li className='mb-2'>
            <strong>Activity Logs</strong>: Information about your interactions with the platform, including service requests, messages, and transactions.
          </li>
        </ul>

        <h2 className='text-2xl font-semibold mb-3'>How We Use Your Information</h2>
        <ul className='list-disc list-inside mb-4'>
          <li className='mb-2'>
            <strong>To Provide and Improve Our Services</strong>: Facilitate connections between Hocal Chiefs and users. Process transactions and service requests. Personalize user experience and improve our platform's functionality.
          </li>
          <li className='mb-2'>
            <strong>Security and Verification</strong>: Verify identities using Aadhaar card information. Protect against fraud and unauthorized access.
          </li>
          <li className='mb-2'>
            <strong>Communication</strong>: Send notifications, updates, and promotional materials. Respond to inquiries and provide customer support.
          </li>
        </ul>

        <h2 className='text-2xl font-semibold mb-3'>Sharing Your Information</h2>
        <ul className='list-disc list-inside mb-4'>
          <li className='mb-2'>
            <strong>With Users and Service Providers</strong>: We share relevant information (e.g., profile details, location, contact information ) to facilitate service requests and communication between Hocal Chiefs and users in a controlled manner.
          </li>
          <li className='mb-2'>
            <strong>Third-Party Services</strong>: We may share data with third-party service providers who assist us in operating our platform, conducting business, or servicing you, as long as these parties agree to keep this information confidential.
          </li>
          <li className='mb-2'>
            <strong>Legal Compliance</strong>: We may disclose your information if required by law or in response to valid requests by public authorities.
          </li>
        </ul>

        <h2 className='text-2xl font-semibold mb-3'>Data Security</h2>
        <p className='mb-6'>
          We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, secure socket layer (SSL) technology, and regular security audits.
        </p>

        <h2 className='text-2xl font-semibold mb-3'>Your Rights</h2>
        <ul className='list-disc list-inside mb-4'>
          <li className='mb-2'>
            <strong>Access and Update</strong>: You have the right to access and update your personal information at any time through your account settings.
          </li>
          <li className='mb-2'>
            <strong>Deletion</strong>: You can request the deletion of your account and personal data, subject to legal and contractual obligations.
          </li>
          <li className='mb-2'>
            <strong>Consent Withdrawal</strong>: You may withdraw your consent for data collection and processing at any time, though this may affect your ability to use certain features of the platform.
          </li>
        </ul>

        <h2 className='text-2xl font-semibold mb-3'>Changes to This Privacy Policy</h2>
        <p className='mb-6'>
          We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any significant changes through the platform or via email.
        </p>

        <h2 className='text-2xl font-semibold mb-3'>Contact Us</h2>
        <p className='mb-2'>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p className='mb-2'>Email: <a href="mailto:support@hocal.in" className='text-yellow-300'>support@hocal.in</a></p>
        {/* <p className='mb-6'>Address: Hocal Privacy Team, [Your Company Address]</p> */}
        <p className='mb-6'>By using the Hocal platform, you agree to the terms outlined in this policy.</p>
      </div>
    </div>

  );
};

export default PrivacyPolicy;
