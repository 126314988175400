import React from 'react';
import { Helmet } from 'react-helmet';
import CreateServiceRequest from '../CreateServiceRequest';

const TripPlanningWithHocal = () => {
    return (

        <div>
            <div className='md:px-[100px] p-5 '>


                <div className="py-10 flex flex-col md:flex-row justify-between items-center h-screen w-full">
                    <div className="text-section md:w-1/2 md:text-left mb-10 md:mb-0">
                        <h1 className="md:text-6xl text-3xl font-bold">Plan Your next & best <span className='text-white bg-yellow-500 rounded-md px-[10px]'>Trip with Hocal</span></h1>
                        <p className="mt-4 text-base">
                            Hocal helps you plan your trips and journeys effortlessly. Discover local attractions, accommodation, and travel tips before you arrive. Make your travel experience smooth and enjoyable with Hocal.
                        </p>
                        <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
                            <button className="bg-yellow-500 hover:bg-yellow-600 text-white py-4 px-6 rounded-md shadow-md focus:outline-none">
                                Download App
                            </button>
                            <button className="bg-white hover:bg-gray-200 text-black py-4 px-6 rounded-md shadow-md focus:outline-none">
                                Know More
                            </button>
                        </div>
                    </div>
                    <div className="image-section md:w-1/2 p-10">
                        <img className=" w-full" src="image/trip-planning-with-hocal.svg" alt="plan your next tour or trip with hocal" loading="lazy" />
                    </div>
                </div>

                <div className='mt-10 pr-5'>
                    <h2 className="text-2xl font-bold mb-4">Why Choose Hocal for Trip Planning?</h2>
                    <p className="mb-4">
                        Hocal offers comprehensive trip planning services to ensure you have all the information you need for a perfect journey:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Detailed guides to local attractions</li>
                        <li>Recommendations for accommodations and dining</li>
                        <li>Travel tips and safety advice</li>
                        <li>Easy access to local services and amenities</li>
                    </ul>
                    <h2 className="text-2xl font-bold mb-4">Services Offered by Hocal for Travelers</h2>
                    <p className="mb-4">
                        Our platform connects you with local experts who can assist with various aspects of your trip, including:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Customized travel itineraries</li>
                        <li>Booking and reservation services</li>
                        <li>Local tour guides</li>
                        <li>Transport arrangements</li>
                        <li>Emergency assistance</li>
                        <li>Event and activity planning</li>
                    </ul>
                    <p className="mb-0">
                        With Hocal, you can enjoy a stress-free travel experience. Download our app today and start planning your next adventure!
                    </p>
                </div>


            </div>
            <CreateServiceRequest />

            <Helmet>
                <title>Trip Planning with Hocal | Plan Your Journey Easily</title>
                <meta name="description" content="Hocal helps you plan your trips and journeys effortlessly. Discover local attractions, accommodation, and travel tips before you arrive. Make your travel experience smooth and enjoyable with Hocal." />
                <meta name="keywords" content="trip planning, travel, journey, tour, Hocal, travel tips, local attractions, accommodation, travel guides" />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Trip Planning with Hocal | Plan Your Journey Easily" />
                <meta property="og:description" content="Hocal helps you plan your trips and journeys effortlessly. Discover local attractions, accommodation, and travel tips before you arrive. Make your travel experience smooth and enjoyable with Hocal." />
                <meta property="og:image" content="https://hocal.in/image/trip-planning-with-hocal.svg" />
                <meta property="og:url" content="https://www.hocal.in/trip-planning" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Trip Planning with Hocal | Plan Your Journey Easily" />
                <meta name="twitter:description" content="Hocal helps you plan your trips and journeys effortlessly. Discover local attractions, accommodation, and travel tips before you arrive. Make your travel experience smooth and enjoyable with Hocal." />
                <meta name="twitter:image" content="https://hocal.in/image/trip-planning-with-hocal.svg" />
            </Helmet>
        </div>



    );
};

export default TripPlanningWithHocal;
