import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const TermsConditions = () => {
  return (
    <div className="p-5 text-lg my-10 md:pt-10 text-black md:px-[100px]">
      <Helmet>
        <title>Hocal Terms and Conditions | User Agreement and Service Policies</title>
        <meta name="description" content="Review the Hocal Services Private Limited Terms and Conditions to understand our user agreement and service policies." />
        <meta name="keywords" content="Hocal terms and conditions, Hocal user agreement, Hocal service policies, Hocal terms, Hocal conditions, Hocal terms of service, user agreement, service policies, Hocal terms page" />
      </Helmet>

      <h1 className="text-3xl  font-bold ">Terms and Conditions</h1>

      <h2 className="text-2xl mb-4">Introduction</h2>
      <p className="mb-4">
        Welcome to Hocal ( Hocal Services Private Limited )! These Terms and Conditions ("T&C") govern your use of our platform, where you can request and provide local services such as delivery, car repair, home repairs, and more. By using our website and mobile application, you agree to comply with and be bound by these T&C.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Definitions</h2>
      <p className="mb-4">
        - <strong>User</strong>: Any person using the Hocal platform, either to provide or request services.<br />
        - <strong>Service Provider/Hocal Chief</strong>: A user offering services through Hocal.<br />
        - <strong>Service Requester</strong>: A user requesting services through Hocal.<br />
        - <strong>Platform</strong>: The Hocal website and mobile application.
      </p>

      <h2 className="text-2xl mb-4 mt-8">User Registration</h2>
      <p className="mb-4">
        - Users must register using their phone number and verify it via an OTP (One-Time Password).<br />
        - Additional information such as name, age, gender, email, and photo can be provided but is not mandatory.<br />
        - There is no age restriction for users.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Service Provision</h2>
      <p className="mb-4">
        - Users can offer and request any service that is not illegal.<br />
        - No special qualifications or certifications are required to become a Service Provider (Hocal Chief).<br />
        - To become a Service Provider, users must provide their Aadhaar number for verification and complete the business details form, including business name, service description, and Aadhaar number.<br />
        - Hocal verifies the Aadhaar number and business details before approving the user as a Service Provider.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Payment Terms</h2>
      <p className="mb-4">
        - Payments are processed via Razorpay, a third-party payment application.<br />
        - There is no platform fee for registering or becoming a Hocal Chief.<br />
        - Service Providers set the Availability Charge and Service Charge. The Availability Charge is a visit fee, while the Service Charge covers the service or product provided.<br />
        - Hocal takes 10-20% of the Availability Charge, and the Service Charge is paid directly to the Service Provider.<br />
        - Refunds, if necessary, will be processed within 7 to 15 days.<br />
        - Availability Charges are transferred to the Service Provider within 24 to 36 hours.
      </p>

      <h2 className="text-2xl mb-4 mt-8">User Conduct</h2>
      <p className="mb-4">
        - Any form of harassment, abuse, or fraud is strictly prohibited.<br />
        - Users found engaging in prohibited activities will have their accounts terminated immediately.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Account Terms</h2>
      <p className="mb-4">
        - Users are responsible for maintaining the confidentiality of their account credentials.<br />
        - Hocal reserves the right to suspend or terminate accounts for violations of these T&C.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Liability Limitations</h2>
      <p className="mb-4">
        - Hocal is not liable for any direct, indirect, incidental, or consequential damages arising from the use of the platform.<br />
        - Users agree that Hocal's liability is limited to the amount paid by the user for services, if applicable.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Dispute Resolution</h2>
      <p className="mb-4">
        - Users agree to resolve disputes amicably and in good faith.<br />
        - Hocal provides a mechanism for users to report and resolve issues.<br />
        - In the event of unresolved disputes, arbitration will be the final means of resolution.<br />
        - Hocal reserves the right to make the final decision in any dispute.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Privacy Policy</h2>
      <p className="mb-4">
        - Our <Link to="/privacy-policy" className="text-yellow-400">Privacy Policy</Link> explains how we handle user data.<br />
        - By using Hocal, users agree to the collection and use of their data as described in the Privacy Policy.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Modifications</h2>
      <p className="mb-4">
        - Hocal reserves the right to modify these T&C at any time.<br />
        - Users will be notified of significant changes through the platform.<br />
        - Continued use of the platform after changes implies acceptance of the new T&C.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Governing Law</h2>
      <p className="mb-4">
        - These T&C are governed by the laws of India.<br />
        - Any legal actions will be handled in the courts of India.
      </p>

      <h2 className="text-2xl mb-4 mt-8">Contact Information</h2>
      <p className="mb-4">
        - For questions or concerns about these T&C, please contact us at hocal.hyperlocal@gmail.com
      </p>

      <p className="mb-4">
        By using Hocal, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
      </p>
    </div>
  );
};

export default TermsConditions;
